import React from "react";

import ChacoLogo from "assets/img/brand/chaco/justicia-logo-chaco.png";
import ChacoLogoLight from "assets/img/brand/chaco/justicia-logo-chaco-light.png";
import ChacoFav from "assets/img/brand/chaco/chaco_iso.png";
import DefaultChaco from "assets/img/brand/chaco/defaultChaco.png";

import InterBannerWA from "assets/img/brand/subastas_electronicas/inter_banners/Inter_bannerWA.webp";


export const site = 'chaco';

export const sources = {
    chaco: {
        url: document.location.origin,
        logo: ChacoLogo,
        logolight: ChacoLogoLight,
        ws_path: `${document.location.origin}/wss`.replace('http', 'ws').replace('https', 'wss'),
        name: 'Subastas Judiciales Chaco',
        navbar_class: 'navbar-chaco',
        headroom_class: 'headroom-chaco',
        favicon: ChacoFav,
        theme_color: '#007f01',
        theme_name: 'chaco',
        defaultImg: DefaultChaco,
        interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

        title: 'Subastas Judiciales',
        recaptcha_key: '6LfW0yUqAAAAANAZDmsNRYCmDRs9h46gc-t9wsdq',
        gtm_ID: 'GTM-TBHGZ78M',
        contact_data: {
            title: <><h4>Secretaría de superintendencia</h4>
                    <h5>Poder Judicial de la Provincia del Chaco</h5></>,
            lines: [
                <p>
                    <b>Secretario de superintendencia:</b><br/>
                    Zalazar Héctor Daniel - Secretario Letrado (Provisorio)
                </p>,
                <p>
                    <b>Dirección:</b><br/>
                    López y planes N° 215
                </p>,
                <p>
                    <b>Localidad:</b><br/>
                    Resistencia
                </p>,
                <p>
                    <b>Departamento:</b><br/>
                    San Fernando
                </p>,
                <p>
                    <b>Provincia:</b><br/>
                    Chaco
                </p>,
                <p>
                    <b>Código Postal:</b><br/>
                    3500
                </p>,
                <p>
                    <b>Cómo llegar:</b><br/>
                    <a href="https://maps.app.goo.gl/F6R3395dJQjdrfM38" target="_blank" rel="noopener noreferrer">Google Maps</a>
                </p>,
                <p>
                    <b>Email:</b><br/>
                    superintendencia@justiciachaco.gov.ar
                </p>,
            ],
        },
        support_email: 'soportechaco@esubasta.ar'
    },
};